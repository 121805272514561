// campuses.js
export const campuses = [
    {
      address: "Doon Campus",
      city: "Kitchener",
      postalCode: "N2G 4M4",
      province: "Ontario",
      country: "Canada",
      addressLine1: "299 Doon Valley Drive",
    },
    {
      address: "Waterloo Campus",
      city: "Waterloo",
      postalCode: "N2J 3Z4",
      province: "Ontario",
      country: "Canada",
      addressLine1: "108 University Avenue East",
    },
    {
      address: "Cambridge Campus",
      city: "Cambridge",
      postalCode: "N1P 0A8",
      province: "Ontario",
      country: "Canada",
      addressLine1: "850 Fountain Street South",
    },
    {
      address: "Guelph Campus",
      city: "Guelph",
      postalCode: "N1G 5J3",
      province: "Ontario",
      country: "Canada",
      addressLine1: "460 Speedvale Avenue West",
    },
    {
      address: "Ingersoll Skills Training Centre",
      city: "Ingersoll",
      postalCode: "N5C 3J7",
      province: "Ontario",
      country: "Canada",
      addressLine1: "420 Thomas Street",
    },
    {
      address: "Stratford Campus",
      city: "Stratford",
      postalCode: "N5A 6S4",
      province: "Ontario",
      country: "Canada",
      addressLine1: "130 Youngs Street",
    },
    {
      address: "Brantford Campus",
      city: "Brantford",
      postalCode: "N3T 2J2",
      province: "Ontario",
      country: "Canada",
      addressLine1: "50 Wellington Street",
    },
    {
      address: "Milton Campus",
      city: "Milton",
      postalCode: "L9T 2X5",
      province: "Ontario",
      country: "Canada",
      addressLine1: "8165 Lawson Road",
    },
    {
      address: "Reuter Drive Training Centre",
      city: "Cambridge",
      postalCode: "N3E 1A9",
      province: "Ontario",
      country: "Canada",
      addressLine1: "25 Reuter Drive",
    },
  ];
  